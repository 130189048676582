<template>
  <div class="productOverall">
    <div class="main-Title bgff"><h2>Close Rule List edit</h2></div>
    <div class="mainBox">
      <el-form ref="postData" :model="postData" :rules="rules" size="mini">
        <el-card shadow="hover" class="mb10">
          <el-row>
            <el-col :span="10" :offset="2">
              <el-form-item label="name" label-width="90px" prop="name">
                <el-input v-model="postData.name" placeholder="" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="check frequency" label-width="90px" class="mb0">
            <el-card shadow="never" class="mb10">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="hours(示例:0~23):" label-width="180px" class="mb5">
                    <el-input v-model="postData.checkHours" placeholder="示例:0~23" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="minutes(示例:0~59):" label-width="180px" class="mb0">
                    <el-input v-model="postData.checkMinutes" placeholder="示例:0~59" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <el-form-item label="data group" label-width="90px" class="mb0">
            <el-card shadow="never" class="mb10">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="postData.dataGroup"
                      placeholder="Please Select data group"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataGroup"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <el-row>
            <el-col :span="10">
              <el-form-item label="compareCvr" label-width="90px" prop="dataCompare">
                <el-input v-model="postData.dataCompare" placeholder="" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="data scope" label-width="90px" class="mb0" prop="dataScope">
            <el-card shadow="never" class="mb10">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="postData.dataScope"
                      placeholder="Please Select data scope"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataScope"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <el-form-item label="data filter" label-width="90px" class="mb0">
            <el-card shadow="never" class="mb10">
              <el-row
                :gutter="10"
                v-for="(item, index) in postData.dataFilterString"
                :key="index"
                :class="postData.dataFilterString.length > 1 ? 'mb5' : 'mb0'"
              >
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="item.key"
                      placeholder="Please Select key"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataFiltersKey"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="item.arithmeticOperator"
                      placeholder="Please Select arithmeticOperator"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataFiltersAO"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <!-- offer_type -->
                  <el-form-item
                    label=""
                    label-width="0px"
                    class="mb0"
                    v-if="item.key == 'offer_type'"
                  >
                    <el-select
                      v-model="item.offer_type"
                      placeholder="Please Select value"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.offerType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <!-- affiliate_id -->
                  </el-form-item>
                  <el-form-item
                    label=""
                    label-width="0px"
                    class="mb0"
                    v-if="item.key === 'affiliate_id'"
                  >
                    <group-select
                      v-model="item.affiliate_id"
                      class="w100"
                      :data-source="affiliatesGroupList"
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="Please select Affiliate"
                      style="width:10.14rem;"
                    />
                  </el-form-item>
                  <!-- pid -->
                  <el-form-item label="" label-width="0px" class="mb0" v-if="item.key === 'pid'">
                    <el-select
                      multiple
                      v-model="item.pid"
                      filterable
                      placeholder="Please Select value"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.pid"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- prod -->
                  <el-form-item label="" label-width="0px" class="mb0" v-if="item.key === 'prod'">
                    <el-select
                      multiple
                      v-model="item.prod"
                      filterable
                      placeholder="Please Select value"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.prod"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-button type="primary" size="mini" v-if="index == 0" @click="dataFilterAdd"
                      >add</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      v-if="index !== 0"
                      @click="dataFilterDel(index)"
                      >delete</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <el-form-item label="data check(and)" label-width="90px" class="mb0">
            <el-card shadow="never" class="mb10">
              <el-row
                :gutter="10"
                v-for="(item, index) in postData.dataCheck"
                :key="index"
                :class="postData.dataCheck.length > 1 ? 'mb5' : 'mb0'"
              >
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="item.key"
                      placeholder="Please Select key"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataChecksKey"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-select
                      v-model="item.arithmeticOperator"
                      placeholder="Please Select arithmeticOperator"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.dataChecksAO"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-input v-model="item.value" placeholder="0,1,2,3,4,5,6,7" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-button type="primary" size="mini" v-if="index === 0" @click="dataCheckAdd"
                      >add</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      v-if="index !== 0"
                      @click="dataCheckDel(index)"
                      >delete</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <el-form-item label="auto action" label-width="90px" class="mb0">
            <el-card shadow="never" class="mb10">
              <el-row :gutter="10">
                <el-col :span="7">
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-checkbox-group v-model="postData.autoAction">
                      <el-checkbox label="managerAlert">manager alert</el-checkbox>
                      <el-checkbox label="pauseOffer">pause offer</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="" label-width="0px" class="mb0">
                    <el-checkbox v-model="autoStartCheckbox">
                      <span class="mr10">auto start N hours later</span>
                      <el-input
                        v-model="postData.autoStart"
                        placeholder="0"
                        :disabled="!autoStartCheckbox"
                      />
                    </el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form-item>
          <div align="center">
            <el-button type="primary" size="mini" @click="save('postData')">save</el-button>
          </div>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
  import compareRuleAddCon from '../../controllers/product/compareRuleList/editCompare';
  export default {
    ...compareRuleAddCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
