import request from 'utils/request';

const api = {
  CLOSE_RULE_PAGE: '/api/close/compare/list',
  CLOSE_RULE_ADD: '/api/close/compare/add',
  CLOSE_RULE_DETAILS: '/api/close/compare/selectById/',
  CLOSE_RULE_STATUS_UPDATE: '/api/close/compare/stopOrStart',
  CLOSE_RULE_DELETE: '/api/close/compare/delete/',
  CLOSE_RULE_UPDATE: '/api/close/compare/update',
};

/**
 * 查询close/rule分页
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function fetchCloseRulePage(query) {
  return request({
    url: api.CLOSE_RULE_PAGE,
    method: 'get',
    params: query,
  });
}

/**
 * 新增close/rule
 */
export function handleAddCloseRule(data) {
  return request({
    url: api.CLOSE_RULE_ADD,
    method: 'post',
    data,
  });
}

/**
 * 更新close/rule
 */
export function handleEditCloseRule(data) {
  return request({
    url: api.CLOSE_RULE_UPDATE,
    method: 'post',
    data,
  });
}

/**
 * 删除
 * @param {*}
 */
export function handleDeleteCloseRule(id) {
  return request({
    url: api.CLOSE_RULE_DELETE + id,
    method: 'post',
  });
}

/**
 * 查询close/rule详情
 */
export function fetchCloseRuleDetails(id) {
  return request({
    url: api.CLOSE_RULE_DETAILS + id,
    method: 'get',
  });
}

/**
 * 修改close/rule状态
 * @param {object} data {id:int,clickCap:int,priority:int}
 */
export function handleUpdateCloseRuleStatus(data) {
  return request({
    url: api.CLOSE_RULE_STATUS_UPDATE,
    method: 'post',
    data,
  });
}
