import { handleEditCloseRule, fetchCloseRuleDetails } from 'api/product/compareRule.js';
import GroupSelect from '@/components/GroupSelect';
import { mapState, mapActions } from 'vuex';
import { getPidsList } from 'api/product/rankSearch';
import { selectProds } from 'api/product/overall';
export default {
  components: {
    GroupSelect,
  },
  data() {
    var validatePriority = (rule, value, callback) => {
      if (value < 1 || value > 99) {
        callback(new Error('Priority:min is 1,max is 99'));
      }
      callback();
    };
    return {
      id: this.$route.query.id,
      postData: {
        dataFilterString: [
          {
            key: 'offer_type',
            arithmeticOperator: '',
            pid: [],
            prod: [],
          },
        ],
        dataCheck: [
          {
            key: '',
            arithmeticOperator: '',
            value: '',
          },
        ],
        autoAction: [],
      },
      autoStartCheckbox: false,
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter name',
            trigger: 'blur',
          },
        ],
        checkMinutes: [
          {
            required: true,
            message: 'Please enter minutes',
            trigger: 'blur',
          },
        ],
        dataScope: [
          {
            required: true,
            message: 'Please enter data scope',
            trigger: 'blur',
          },
        ],
        priority: [
          {
            required: true,
            message: 'Please enter priority',
            trigger: 'blur',
          },
          { validator: validatePriority, trigger: 'blur' },
        ],
      },
      loading: {
        save: false,
      },
      options: {
        dataGroup: [
          { label: 'offer', value: 'offer' },
          { label: 'pid', value: 'pid' },
          { label: 'subid', value: 'subid' },
        ],
        dataScope: [
          { label: 'recent 1 hour', value: '1' },
          { label: 'recent 2 hour', value: '2' },
          { label: 'recent 3 hour', value: '3' },
          { label: 'recent 4 hour', value: '4' },
          { label: 'recent 8 hour', value: '8' },
        ],
        dataFiltersKey: [
          { label: 'offer_type', value: 'offer_type' },
          { label: 'affiliate_id', value: 'affiliate_id' },
          { label: 'pid', value: 'pid' },
          { label: 'prod', value: 'prod' },
        ],
        dataFiltersAO: [
          { label: '=', value: '=' },
          { label: '!=', value: '!=' },
        ],
        offerType: [
          { label: 'ua', value: 'ua' },
          { label: 'rt', value: 'rt' },
        ],
        pid: [],
        prod: [],
        dataChecksKey: [
          { label: 'cvr', value: 'installRate' },
          { label: 'rejection Rate', value: 'rejectionRate' },
          { label: 'click', value: 'click' },
          { label: 'install', value: 'install' },
          { label: 'rejection', value: 'rejection' },
        ],
        dataChecksAO: [
          { label: '>', value: '>' },
          { label: '<', value: '<' },
          { label: '=', value: '=' },
          { label: '>=', value: '>=' },
          { label: '<=', value: '<=' },
        ],
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
  mounted() {
    this.pidsList();
    this.getAffiliateList();
    this.getDetails();
    this.prodsList();
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // 获取pid
    pidsList() {
      getPidsList().then((res) => {
        this.options.pid = res.result;
      });
    },
    prodsList() {
      selectProds().then((res) => {
        this.options.prod = res.result;
      });
    },
    // 获取详情
    getDetails() {
      fetchCloseRuleDetails(this.id).then((res) => {
        console.log(res);
        res.result.dataCheck
          ? (res.result.dataCheck = JSON.parse(res.result.dataCheck))
          : (res.result.dataCheck = [{ key: '', arithmeticOperator: '', value: '' }]);
        this.postData = Object.assign({}, res.result);
        // this.postData.dataCheck = res.result.dataCheck
        //   ? JSON.parse(res.result.dataCheck)
        //   : [{ key: '', arithmeticOperator: '', value: '' }];
        this.postData.dataFilterString.forEach((e) => {
          if (e.key === 'pid') {
            e.pid = e.pid.split(',');
          }
          if (e.key === 'prod') {
            e.prod = e.prod.split(',');
          }
        });
        this.postData.autoAction = res.result.autoAction.split(',');
        if (res.result.autoStart > 0) {
          this.autoStartCheckbox = true;
        }
      });
    },
    // 添加 data Filter item
    dataFilterAdd() {
      const dataFiltersObj = {
        key: 'offer_type',
        arithmeticOperator: '',
        pid: [],
        prod: [],
      };
      this.postData.dataFilterString.push(dataFiltersObj);
    },
    // 删除 data Filter item
    dataFilterDel(index) {
      this.postData.dataFilterString.splice(index, 1);
    },
    // 添加 data check(and) item
    dataCheckAdd() {
      const dataCheckObj = {
        key: '',
        arithmeticOperator: '',
        value: '',
      };
      this.postData.dataCheck.push(dataCheckObj);
    },
    // 删除 data check(and) item
    dataCheckDel(index) {
      this.postData.dataCheck.splice(index, 1);
    },
    // 保存数据
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.loading.save = true;
        let query = JSON.parse(JSON.stringify(this.postData));
        if (!this.autoStartCheckbox) {
          delete query.autoStart;
        }
        delete query.dataFilter;
        // 拼接dataFilter
        const dataFilterArr = query.dataFilterString;
        for (var i = 0; i < dataFilterArr.length; i++) {
          switch (dataFilterArr[i].key) {
            case 'offer_type':
              delete dataFilterArr[i].affiliate_id;
              delete dataFilterArr[i].pid;
              delete dataFilterArr[i].prod;
              break;
            case 'affiliate_id':
              delete dataFilterArr[i].offer_type;
              delete dataFilterArr[i].pid;
              delete dataFilterArr[i].prod;
              break;
            case 'pid':
              delete dataFilterArr[i].affiliate_id;
              delete dataFilterArr[i].offer_type;
              delete dataFilterArr[i].prod;
              dataFilterArr[i].pid = dataFilterArr[i].pid.toString();
              break;
            case 'prod':
              delete dataFilterArr[i].affiliate_id;
              delete dataFilterArr[i].offer_type;
              delete dataFilterArr[i].pid;
              dataFilterArr[i].prod = dataFilterArr[i].prod.toString();
              break;
            default:
              break;
          }
        }
        /*  if (this.postData.autoAction) {
          query.autoAction = this.postData.autoAction.toString();
        }*/
        query.autoAction = this.postData.autoAction.toString();
        console.log(query);
        handleEditCloseRule(query)
          .then((res) => {
            if (res.code === 200) {
              console.log(res);
              this.$message({
                message: '修改成功',
                type: 'success',
              });
              this.$router.push({
                path: '/offer/close/compare',
              });
            } else {
              this.loading.save = false;
              this.$message.error('更新失败！' + res.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading.save = false;
            this.$message.error('添加失败！');
          });
      });
    },
  },
};
